import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				RELAXSPA
			</title>
			<meta name={"description"} content={"Вітаємо вас у RelaxSpa, де кожен момент присвячений вашому релаксу та здоров’ю. Наш заклад створений для того, щоб надати вам незабутні відчуття відпочинку і відновлення.\n\n"} />
			<meta property={"og:title"} content={"RELAXSPA"} />
			<meta property={"og:description"} content={"Вітаємо вас у RelaxSpa, де кожен момент присвячений вашому релаксу та здоров’ю. Наш заклад створений для того, щоб надати вам незабутні відчуття відпочинку і відновлення.\n\n"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6668b161fdcae00021e34089/images/photo-jacuzzi.jpg?v=2024-06-12T08:55:50.619Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6668b161fdcae00021e34089/images/photo-jacuzzi.jpg?v=2024-06-12T08:55:50.619Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6668b161fdcae00021e34089/images/photo-jacuzzi.jpg?v=2024-06-12T08:55:50.619Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6668b161fdcae00021e34089/images/photo-jacuzzi.jpg?v=2024-06-12T08:55:50.619Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6668b161fdcae00021e34089/images/photo-jacuzzi.jpg?v=2024-06-12T08:55:50.619Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6668b161fdcae00021e34089/images/photo-jacuzzi.jpg?v=2024-06-12T08:55:50.619Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6668b161fdcae00021e34089/images/photo-jacuzzi.jpg?v=2024-06-12T08:55:50.619Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header23>
			<Override slot="text1" />
			<Override slot="link" />
			<Override slot="text2" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header23>
		<Section padding="60px 0 60px 0" md-padding="30px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				align-self="center"
			>
				<Text
					font="--headline2"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					margin="0px 0px 32px 0px"
					color="--light"
				>
					Про нас
				</Text>
				<Text
					color="--light"
					font="--lead"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 16px 0px"
					margin="0px 0px 0px 0px"
				>
					RelaxSpa створений для того, щоб допомогти вам знайти баланс між тілом і душею. Наша місія – забезпечити вам незабутній відпочинок та відновлення сил через професійні спа-процедури.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/6668b161fdcae00021e34089/images/images%20%282%29.jpeg?v=2024-06-12T08:55:50.610Z"
					width="100%"
					max-height="auto"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					srcSet="https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/images%20%282%29.jpeg?v=2024-06-12T08%3A55%3A50.610Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/images%20%282%29.jpeg?v=2024-06-12T08%3A55%3A50.610Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/images%20%282%29.jpeg?v=2024-06-12T08%3A55%3A50.610Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/images%20%282%29.jpeg?v=2024-06-12T08%3A55%3A50.610Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/images%20%282%29.jpeg?v=2024-06-12T08%3A55%3A50.610Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/images%20%282%29.jpeg?v=2024-06-12T08%3A55%3A50.610Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/images%20%282%29.jpeg?v=2024-06-12T08%3A55%3A50.610Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="24px 0 60px 0" md-padding="30px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				md-margin="0px 0px 30px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				md-display="flex"
			>
				<Image
					height="auto"
					src="https://uploads.quarkly.io/6668b161fdcae00021e34089/images/images%20%281%29.jpeg?v=2024-06-12T08:55:50.605Z"
					lg-width="320px"
					lg-height="320px"
					width="100%"
					min-width="500px"
					srcSet="https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/images%20%281%29.jpeg?v=2024-06-12T08%3A55%3A50.605Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/images%20%281%29.jpeg?v=2024-06-12T08%3A55%3A50.605Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/images%20%281%29.jpeg?v=2024-06-12T08%3A55%3A50.605Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/images%20%281%29.jpeg?v=2024-06-12T08%3A55%3A50.605Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/images%20%281%29.jpeg?v=2024-06-12T08%3A55%3A50.605Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/images%20%281%29.jpeg?v=2024-06-12T08%3A55%3A50.605Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/images%20%281%29.jpeg?v=2024-06-12T08%3A55%3A50.605Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				flex-direction="column"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="auto"
				display="flex"
				justify-content="center"
				md-width="100%"
				align-items="flex-end"
			>
				<Box display="flex" align-items="center" margin="0px 0px 16px 0px" justify-content="flex-end">
					<Box display="flex" align-items="center" width="auto" />
					<Box display="flex" align-items="center" width="60%">
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif">
							Ми цінуємо якість, турботу та інновації. В RelaxSpa ми використовуємо лише найкращі продукти та сучасні методи для досягнення максимального ефекту від кожної процедури.
							<br />
						</Text>
					</Box>
				</Box>
				<Box align-items="center" margin="0px 0px 16px 0px" display="flex" justify-content="flex-end">
					<Box display="flex" align-items="center" width="auto" />
					<Box display="flex" align-items="center" width="60%" />
				</Box>
				<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
					<Box display="flex" align-items="center" width="60%" />
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" md-padding="20px 0 12px 0" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Image
					object-fit="cover"
					lg-max-height="230px"
					src="https://uploads.quarkly.io/6668b161fdcae00021e34089/images/photo-jacuzzi.jpg?v=2024-06-12T08:55:50.619Z"
					max-height="400px"
					width="100%"
					srcSet="https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/photo-jacuzzi.jpg?v=2024-06-12T08%3A55%3A50.619Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/photo-jacuzzi.jpg?v=2024-06-12T08%3A55%3A50.619Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/photo-jacuzzi.jpg?v=2024-06-12T08%3A55%3A50.619Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/photo-jacuzzi.jpg?v=2024-06-12T08%3A55%3A50.619Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/photo-jacuzzi.jpg?v=2024-06-12T08%3A55%3A50.619Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/photo-jacuzzi.jpg?v=2024-06-12T08%3A55%3A50.619Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/photo-jacuzzi.jpg?v=2024-06-12T08%3A55%3A50.619Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Box
					empty-min-height="64px"
					empty-border-color="LightGray"
					width="100%"
					empty-min-width="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					margin="40px 0px 0px 0px"
					lg-width="70%"
					md-width="100%"
				>
					<Text margin="0px 0px 16px 0px" color="--light" font="--base" text-align="left">
						RelaxSpa заснований у 2020 році з метою створення унікального місця для відпочинку та релаксу. За цей час ми здобули довіру наших клієнтів і продовжуємо розвиватися, щоб надавати ще кращі послуги.
					</Text>
				</Box>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
			>
				<Image
					src="https://uploads.quarkly.io/6668b161fdcae00021e34089/images/3.webp?v=2024-06-12T08:55:50.617Z"
					max-height="400px"
					width="100%"
					object-fit="cover"
					lg-max-height="230px"
					srcSet="https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/3.webp?v=2024-06-12T08%3A55%3A50.617Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/3.webp?v=2024-06-12T08%3A55%3A50.617Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/3.webp?v=2024-06-12T08%3A55%3A50.617Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/3.webp?v=2024-06-12T08%3A55%3A50.617Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/3.webp?v=2024-06-12T08%3A55%3A50.617Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/3.webp?v=2024-06-12T08%3A55%3A50.617Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6668b161fdcae00021e34089/images/3.webp?v=2024-06-12T08%3A55%3A50.617Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Box
					empty-min-height="64px"
					empty-border-color="LightGray"
					width="100%"
					empty-min-width="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					margin="40px 0px 0px 0px"
					lg-width="70%"
					md-width="100%"
				>
					<Text margin="0px 0px 16px 0px" color="--light" font="--base" text-align="left">
						Ми віримо, що здоров’я та краса починаються з внутрішньої гармонії. Тому в RelaxSpa ми прагнемо забезпечити вам найкращі умови для відновлення та досягнення балансу.
					</Text>
					<Button
						background="--color-green"
						border-radius="13px"
						min-width="200px"
						max-width="100%"
						margin="0px auto 0px auto"
						text-align="center"
						type="link"
						text-decoration-line="initial"
						href="/menu"
					>
						ПОСЛУГИ
					</Button>
				</Box>
			</Box>
		</Section>
		<Components.Footer1 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});